// name
// Email
// Tel
// Career
// File
// Message
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function Form() {
  const form = useRef();
  const [formStatus, setFormStatus] = useState(false);

  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publicId = process.env.REACT_APP_PUBLIC_ID;

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(serviceId, templateId, form.current, publicId)
      .then((result) => {
        console.log(result.text);
        setFormStatus(true);
      })
      .catch((err) => console.log(err.text));
  };

  return (
    <div>
      <span className="form-txt">*は必須項目です</span>
      <br />
      <br />
      <form
        ref={form}
        onSubmit={sendEmail}
        enctype="multipart/form-data"
        method="post"
      >
        <label className="input-label">お名前*</label>
        <br />
        <input
          type="text"
          name="name"
          className="text-input"
          placeholder=""
          required
        />
        <br />
        <br />
        <label className="input-label">メールアドレス*</label>
        <br />
        <input
          type="email"
          name="email"
          className="text-input"
          placeholder="example@email.com"
          required
        />
        <br />
        <br />
        <label className="input-label">電話番号</label>
        <br />
        <input type="text" name="phone" className="text-input" placeholder="" />
        <br />
        <br />
        <label className="input-label">ご経歴</label>
        <br />
        <textarea
          type="text"
          name="career"
          className="text-textarea"
          placeholder=""
        />
        <br />
        <br />
        <label className="input-label">執筆作品*</label>
        <br />
        <div className="url-txt">※アップロードしたリンクを入力してください</div>
        <div className="url-txt url-txt-2">
          （Dropbox、GoogleDrive、ギガファイル便、FireStorage等）
        </div>

        <br />
        <div className="">
          <input
            type="text"
            name="linkurl"
            className="text-input"
            placeholder="URL"
            required
          />
        </div>
        <br />
        <br />
        <label className="input-label">メッセージなど</label>
        <br />
        <textarea
          type="text"
          name="message"
          className="text-textarea"
          placeholder=""
        />
        <br />
        <br />
        <br />
        <div className="form-txt2">
          送信することで個人情報方針に同意したこととみなします。
        </div>
        <br />
        <button className="form-btn" type="submit" value="Submit">
          送信する
        </button>
      </form>
      <br />
      {formStatus ? (
        <div className="alert alert-success">メールが送信されました。</div>
      ) : (
        ""
      )}
    </div>
  );
}
