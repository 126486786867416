import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>
            <span className="palel-title1">
              採用合否の連絡は、応募後そのくらいで来ますか？
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            およそ２ヶ月内にご連絡差し上げます。
            <br />
            応募時期によっては、少しお待たせしてしまう場合がございます。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            <span className="palel-title1">
              すでに脚本家としてデビューしてますが、応募は可能ですか？
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            応募は可能です。ただし、脚本を執筆するのではなくプロットや企画書の作成などをお願いすることが殆どだと思いますので、その点認識の上ご応募ください。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            <span className="palel-title1">
              採用になったら、すぐに所属になりますか？
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            合否時期により異なります。何か条件がある場合にも、お気軽にご相談ください。
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>
            <span className="palel-title1">
              リオネスで経験を積んで、脚本家になることは可能ですか？
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            本人の実力次第で可能です。
            <br />
            様々なプロデューサーや監督と知り合う機会がありますので、
            実力を磨いてチャンスを掴むことは可能です。こちらは脚本家デビューすることを保証するものではありませんので、ご理解ください。
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
