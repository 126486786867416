import React from "react";

import Faq from "./Faq";
import Form from "./Form";

export default function Home() {
  return (
    <div className="">
      <div className="subtitle">Creator Recruit</div>
      <div className="wrapper-part">
        <div className="wrapper-title">プロットライター募集</div>
        <div className="title-underline"></div>
        {/* text */}
        <div className="wrapper-txt1 wrapper-txt">
          ドラマや映画作品の根幹となる"企画"、"シナリオ"は、我々にとって財産です。
          <br />
          その財産を一緒に作っていく仲間になりませんか？
        </div>
        <br />
        <br />
        <div className="wrapper-txt">
          リオネスピクチャーズでは、プロットライターを募集しています。
          <br />
          プロデューサーと共に映像作品の企画実現に向けて、企画書やプロット作成のお仕事をしてもらいます。
          <br />
          もちろん企画の持ち込みも歓迎致します。
        </div>
        <br />
        <br />
        <br />
        <div className="wrapper-txt">
          【応募資格】
          <br />
          <div className="wrapper-txt2">
            ・自身が執筆した作品を提出できる方
            <br />
            ・エンタメが幅広く好きな方
            <br />
            ・ドラマ業界でなくても漫画・コント・小説・アニメ・ゲームなどの分野で物語を作ってきた方、あるいは経験はなくても物語を作ることを生業にしたい方
            <br />
            ・学歴・年齢不詳
          </div>
          <br />
          <div className="wrapper-txt">
            【待遇・給与など】
            <br />
            <div className="wrapper-txt2">
              社会保障あり
              <br />
              給与：月額22万〜　スキルによって要相談
              <br />
              勤務形態：詳細はご相談ください
              <br />
              勤務時間：フレックスタイム制
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
      {/* FAQ */}
      <div className="faq-part">
        <div className="subtitle-2">よくあるご質問（FAQ）</div>
        <div className="title-underline2"></div>
        <br />
        <br />
        <Faq />
      </div>
      <br />
      <br />
      <br />
      <br />
      {/* FORM */}
      <div className="form-part">
        <div className="subtitle-2">応募フォーム</div>
        <div className="title-underline2"></div>
        <br />
        <div>
          <Form />
        </div>
      </div>
      {/* PrivacyPolicy */}
      <div className="policy-part">
        <div className="subtitle-2 subtitle-2-sm">
          個人情報の取り扱いについて
        </div>
        <div className="title-underline2"></div>
        <br />
        <div className="wrapper-txt">
          （１）応募の際にご記入いただいた個人情報は、審査および本件に関する諸連絡のために使用致します。
          <br />
          （２）個人情報の取り扱いについては、弊社のプライバシーポリシーをご確認ください。
        </div>
      </div>
      {/* Footer */}
      <footer className="footer-part">
        <div className="footer-logo"></div>
        <div className="footer-link-a">
          <a
            href="https://lyonessepictures.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="footer-link">→ LYONESSE PICTURES</div>
          </a>
        </div>
        <br />
        <br />
        <div className="copy-light">©️LyonessePictures, 2023</div>
      </footer>
    </div>
  );
}
